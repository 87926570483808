import { inject, Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AgdirColor } from '@agdir/ui/colors';
import { I18nService } from '@agdir/i18n/angular';

export type AgdirSnackBarOptions = {
	text?: string;
	color?: AgdirColor;
	duration?: number;
	closable?: boolean;
	action?: { label: string; actionFn: () => void };
};

@Injectable({ providedIn: 'root' })
export class AgdirSnackbarService {
	service = inject(NzMessageService);
	i18nService = inject(I18nService);
	open(text: string, options?: AgdirSnackBarOptions) {
		this.service.info(this.i18nService.translate(text), {
			nzDuration: options?.duration || 3000,
			nzAnimate: true,
			nzPauseOnHover: true,
		});
	}
}
